import React, {useEffect, useState} from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import {useDispatch, useSelector} from "react-redux";
import {defaultErrorMessageToast, getToast} from "../../utils/toastHelper";
import {useLocation, useNavigate} from "react-router-dom";
import {loadCalendarEvents, loadMajalis, loadMajalisByPanrent, loadRegions} from "../../store/calendar/calendar.slice";
import {capitalizeString, prettifyLabel} from "../../utils/utils";
import {getSelectionDropdown} from "../../utils/inputHelper";
import {Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";
import {keycloak} from "../../app_layout/Keycloak";
import {getUsersTanzeemUnitByLevel, isAdmin, isMKADAccount, isNational, isRegional} from "../../utils/userHelper";
import {MultiSelect} from "primereact/multiselect";


export default function Dashboard() {
    const dispatch = useDispatch();
    const location = useLocation();
    // Get a specific query parameter
    const region = new URLSearchParams(location.search).get('region');
    const majlis = new URLSearchParams(location.search).get('majlis');
    const [myEvents, setEvents] = React.useState([]);
    const [isToastOpen, setToastOpen] = React.useState(false);
    const [toastText, setToastText] = React.useState();
    const [majlises, setMajlises] = React.useState();
    const [majlisesByParent, setMajlisesByParent] = React.useState();
    const [regionss, setRegions] = React.useState();
    const [selectedMajlis, setSelectedMajlis] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const {calendarEvents, calendarEventsLoading, majalis, majalisLoading, regions, regionLoading, majalisByParent} = useSelector(state => state.calendars);
    const toast = getToast();
    const toastErrorMessage = (response) => {defaultErrorMessageToast(toast,response)};
    const navigate = useNavigate()

    const onChange=(event)=>{
        const {value} = event?.target;
        const params = new URLSearchParams({majlis: value.key });
        navigate({ pathname: location.pathname, search: params.toString() });
    }
    
    useEffect(() =>{
        
        dispatch(loadMajalis( {finalData: {}, callbackError: toastErrorMessage}))

        if(isNational(keycloak))
            dispatch(loadRegions( {finalData: {}, callbackError: toastErrorMessage}))

        if(isRegional(keycloak))
            dispatch(loadMajalisByPanrent( {finalData: {parentKey: getUsersTanzeemUnitByLevel(keycloak, "region")}, callbackError: toastErrorMessage}))
        
        loadEvents()
    },[])

    useEffect(() =>{
        loadEvents()
    },[majlis, selectedRegions, selectedMajlis])

    const loadEvents = () => {
        if(isMKADAccount(keycloak))
        {
            if(isNational(keycloak)) {
                dispatch(loadCalendarEvents({
                    finalData: {
                        page: 0,
                        size: -1,
                        region: selectedRegions.map(region => region.key),
                        majlis: selectedMajlis.map(majlisItem => majlisItem.key)
                    }, callbackError: toastErrorMessage
                }))
            }
            else if(isRegional(keycloak)){
                dispatch(loadCalendarEvents({
                    finalData: {
                        page: 0,
                        size: -1,
                        region: null,
                        majlis: selectedMajlis.map(majlisItem => majlisItem.key)
                    }, callbackError: toastErrorMessage
                }))
                
            }else{
                dispatch(loadCalendarEvents( {finalData: {page: 0, size: -1, region: null, majlis: null}, callbackError: toastErrorMessage}))    
            }
        }else{
            dispatch(loadCalendarEvents( {finalData: {page: 0, size: -1, region: null, majlis: [majlis]}, callbackError: toastErrorMessage}))    
        }
    }
    
    
    const rangeFormatter = (range, culture, localizer) => {
        return moment(range.start).format("HH:mm") +" - "+ moment(range.end).format("HH:mm")
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.color;
        var style = {
            backgroundColor: backgroundColor
        };
        return {
            style: style
        };
    };
    
    const localizer = momentLocalizer(moment)
    localizer.formats.timeGutterFormat = "HH:mm"
    localizer.formats.agendaTimeFormat = "HH:mm"
    localizer.formats.agendaTimeRangeFormat = rangeFormatter
    localizer.formats.eventTimeRangeFormat = rangeFormatter
    
    useEffect(() => {
        let myevents = calendarEvents?.content.map(event => {
            //console.log(event);
            let color;
            switch (event?.eventType?.tanzeemLevel) {
                case "majlis":
                    color="#0b00a8";
                    break
                case "region":
                    color="#186700";
                    break
                case "national":
                    color="#ffe642";
                    break

            }
            return {
                "start": new Date(event.startDateTime),
                "end": new Date(event.endDateTime),
                "title": event.eventType.label + "("+ (prettifyLabel(event.tanzeemUnit))+")",
                "color": color,
                "eventDetails": event
            }
        });
        setEvents(myevents)
        
        // getJson('https://trial.mobiscroll.com/events/?vers=5', (events) => {
        //     setEvents(events);
        // }, 'jsonp');
    }, [calendarEvents]);

    useEffect(() => {
        let result;
        if(majalisByParent?.items)
        {
            result = {items: majalisByParent?.items};
        }    
            
       setMajlisesByParent(result)
    }, [majalisByParent]);

    useEffect(() => {
        let result;
        if(majalis?.items)
        {
            result = {items: majalis?.items};
        }

        setMajlises(result)
    }, [majalis]);

    useEffect(() => {
        let result;
        if(regions?.items)
        {
            result = {items: regions?.items};
        }
        setRegions(result)
    }, [regions]);

    const closeToast = React.useCallback(() => {
        setToastOpen(false);
    }, []);

    const onEventClick = React.useCallback((event) => {
        setToastText(event.event.title);
        setToastOpen(true);
    }, []);
    
    const view = React.useMemo(() => {
        return {
            calendar: { type: 'month',labels: true },
            agenda: { type: 'month' }
        };
    }, []);

    return <div className="content">

        { !isMKADAccount(keycloak) ? <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <div className="field xl:w-3 lg:w-3 md:w-3 sm:w-10 w-10">
                <span className="p-float-label">
                    <Dropdown id="majlis-dropdown" 
                              className="w-full md:w-14rem" 
                              options={majlises?.items}
                              optionLabel="defaultLabel" 
                              value={majlises?.items.find(item => item.key === majlis)}
                              onChange={onChange}/>
                    <label htmlFor="majlis-dropdown">Wähle deine Majlis aus</label>
                </span>
            </div>
            
        </div>: ""}
        { isMKADAccount(keycloak) && isNational(keycloak) ? <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <div className="field">
                <span className="p-float-label">
                    <MultiSelect value={selectedRegions}
                                 onChange={(e) => setSelectedRegions(e.value)}
                                 options={regionss?.items}
                                 optionLabel="defaultLabel"
                                 display="chip"
                                 filter
                                 placeholder="Select Region"
                                 className="w-full md:w-20rem"/>
                    <label htmlFor="majlis-dropdown">Region Auswahl</label>
                </span>
                </div>
                <div className="field">
                <span className="p-float-label">
                    <MultiSelect value={selectedMajlis}
                                 onChange={(e) => setSelectedMajlis(e.value)}
                                 options={majlises?.items}
                                 optionLabel="defaultLabel"
                                 display="chip"
                                 filter
                                 placeholder="Select Majlis"
                                 className="w-full md:w-20rem"/>
                    <label htmlFor="majlis-dropdown">Majlis Auswahl</label>
                </span>
                </div>
        </div> : ""}
        { isMKADAccount(keycloak) && !isNational(keycloak) && isRegional(keycloak) ? <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <div className="field">
                <span className="p-float-label">
                    <MultiSelect value={selectedMajlis}
                                 onChange={(e) => setSelectedMajlis(e.value)}
                                 options={majlisesByParent?.items}
                                 optionLabel="defaultLabel"
                                 display="chip"
                                 filter
                                 placeholder="Select Majlis"
                                 className="w-full md:w-20rem"/>
                    <label htmlFor="majlis-dropdown">Majlis Auswahl</label>
                </span>
            </div>
        </div> : ""}
        <div className="card">

            <div>
                <Calendar
                    localizer={localizer}
                    events={myEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 500}}
                    eventPropGetter={eventStyleGetter}
                />
            </div>
            {/*<Page>*/}
            {/*    <Eventcalendar*/}
            {/*        locale={localeDe}*/}
            {/*        theme="windows"*/}
            {/*        themeVariant="light"*/}
            {/*        clickToCreate={false}*/}
            {/*        dragToCreate={false}*/}
            {/*        dragToMove={false}*/}
            {/*        dragToResize={false}*/}
            {/*        eventDelete={false}*/}
            {/*        data={myEvents}*/}
            {/*        view={view}*/}
            {/*        onEventClick={onEventClick}*/}
            {/*    />*/}
            {/*    <Toast*/}
            {/*        message={toastText}*/}
            {/*        isOpen={isToastOpen}*/}
            {/*        onClose={closeToast}*/}
            {/*    />*/}
            {/*</Page>*/}
        </div>
    </div>

}