/* tslint:disable */
/* eslint-disable */
/**
 * MKAD Platform API
 * Core MKAD Services
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fatha.idrees@khuddam.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * TanzeemUnitsApi - axios parameter creator
 * @export
 */
export const TanzeemUnitsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create a new Tanzeem Unit
         * @param {TanzeemUnit} tanzeemUnit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeemUnit: (tanzeemUnit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemUnit' is not null or undefined
            assertParamExists('addTanzeemUnit', 'tanzeemUnit', tanzeemUnit);
            const localVarPath = `/api/tanzeem/v1/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(tanzeemUnit, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemManagers for a Unit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemManagersPerUnit: (tanzeemUnitKey, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemUnitKey' is not null or undefined
            assertParamExists('getTanzeemManagersPerUnit', 'tanzeemUnitKey', tanzeemUnitKey);
            const localVarPath = `/api/tanzeem/v1/units/{tanzeemUnitKey}/managers`
                .replace(`{${"tanzeemUnitKey"}}`, encodeURIComponent(String(tanzeemUnitKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemMembers for a Unit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemMembersPerUnit: (tanzeemUnitKey, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemUnitKey' is not null or undefined
            assertParamExists('getTanzeemMembersPerUnit', 'tanzeemUnitKey', tanzeemUnitKey);
            const localVarPath = `/api/tanzeem/v1/units/{tanzeemUnitKey}/members`
                .replace(`{${"tanzeemUnitKey"}}`, encodeURIComponent(String(tanzeemUnitKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemUnit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnit: (tanzeemUnitKey, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemUnitKey' is not null or undefined
            assertParamExists('getTanzeemUnit', 'tanzeemUnitKey', tanzeemUnitKey);
            const localVarPath = `/api/tanzeem/v1/units/{tanzeemUnitKey}`
                .replace(`{${"tanzeemUnitKey"}}`, encodeURIComponent(String(tanzeemUnitKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemUnits
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnits: (page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/tanzeem/v1/units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemUnits chidlren
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnitsChildren: (tanzeemUnitKey, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemUnitKey' is not null or undefined
            assertParamExists('getTanzeemUnitsChildren', 'tanzeemUnitKey', tanzeemUnitKey);
            const localVarPath = `/api/tanzeem/v1/units/{tanzeemUnitKey}/children`
                .replace(`{${"tanzeemUnitKey"}}`, encodeURIComponent(String(tanzeemUnitKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TanzeemUnitsApi - functional programming interface
 * @export
 */
export const TanzeemUnitsApiFp = function (configuration) {
    const localVarAxiosParamCreator = TanzeemUnitsApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a new Tanzeem Unit
         * @param {TanzeemUnit} tanzeemUnit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeemUnit(tanzeemUnit, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTanzeemUnit(tanzeemUnit, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemUnitsApi.addTanzeemUnit']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemManagers for a Unit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemManagersPerUnit(tanzeemUnitKey, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemManagersPerUnit(tanzeemUnitKey, page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemUnitsApi.getTanzeemManagersPerUnit']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemMembers for a Unit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemMembersPerUnit(tanzeemUnitKey, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemMembersPerUnit(tanzeemUnitKey, page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemUnitsApi.getTanzeemMembersPerUnit']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemUnit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnit(tanzeemUnitKey, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemUnit(tanzeemUnitKey, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemUnitsApi.getTanzeemUnit']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemUnits
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnits(page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemUnits(page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemUnitsApi.getTanzeemUnits']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemUnits chidlren
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnitsChildren(tanzeemUnitKey, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemUnitsChildren(tanzeemUnitKey, page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemUnitsApi.getTanzeemUnitsChildren']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * TanzeemUnitsApi - factory interface
 * @export
 */
export const TanzeemUnitsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = TanzeemUnitsApiFp(configuration);
    return {
        /**
         * Create a new Tanzeem Unit
         * @param {TanzeemUnit} tanzeemUnit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeemUnit(tanzeemUnit, options) {
            return localVarFp.addTanzeemUnit(tanzeemUnit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemManagers for a Unit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemManagersPerUnit(tanzeemUnitKey, page, size, sort, options) {
            return localVarFp.getTanzeemManagersPerUnit(tanzeemUnitKey, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemMembers for a Unit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemMembersPerUnit(tanzeemUnitKey, page, size, sort, options) {
            return localVarFp.getTanzeemMembersPerUnit(tanzeemUnitKey, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemUnit
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnit(tanzeemUnitKey, options) {
            return localVarFp.getTanzeemUnit(tanzeemUnitKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemUnits
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnits(page, size, sort, options) {
            return localVarFp.getTanzeemUnits(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemUnits chidlren
         * @param {string} tanzeemUnitKey TanzeemUnit Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnitsChildren(tanzeemUnitKey, page, size, sort, options) {
            return localVarFp.getTanzeemUnitsChildren(tanzeemUnitKey, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TanzeemUnitsApi - object-oriented interface
 * @export
 * @class TanzeemUnitsApi
 * @extends {BaseAPI}
 */
export class TanzeemUnitsApi extends BaseAPI {
    /**
     * Create a new Tanzeem Unit
     * @param {TanzeemUnit} tanzeemUnit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemUnitsApi
     */
    addTanzeemUnit(tanzeemUnit, options) {
        return TanzeemUnitsApiFp(this.configuration).addTanzeemUnit(tanzeemUnit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemManagers for a Unit
     * @param {string} tanzeemUnitKey TanzeemUnit Key
     * @param {number} [page] The number of page.
     * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
     * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemUnitsApi
     */
    getTanzeemManagersPerUnit(tanzeemUnitKey, page, size, sort, options) {
        return TanzeemUnitsApiFp(this.configuration).getTanzeemManagersPerUnit(tanzeemUnitKey, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemMembers for a Unit
     * @param {string} tanzeemUnitKey TanzeemUnit Key
     * @param {number} [page] The number of page.
     * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
     * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemUnitsApi
     */
    getTanzeemMembersPerUnit(tanzeemUnitKey, page, size, sort, options) {
        return TanzeemUnitsApiFp(this.configuration).getTanzeemMembersPerUnit(tanzeemUnitKey, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemUnit
     * @param {string} tanzeemUnitKey TanzeemUnit Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemUnitsApi
     */
    getTanzeemUnit(tanzeemUnitKey, options) {
        return TanzeemUnitsApiFp(this.configuration).getTanzeemUnit(tanzeemUnitKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemUnits
     * @param {number} [page] The number of page.
     * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
     * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemUnitsApi
     */
    getTanzeemUnits(page, size, sort, options) {
        return TanzeemUnitsApiFp(this.configuration).getTanzeemUnits(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemUnits chidlren
     * @param {string} tanzeemUnitKey TanzeemUnit Key
     * @param {number} [page] The number of page.
     * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
     * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemUnitsApi
     */
    getTanzeemUnitsChildren(tanzeemUnitKey, page, size, sort, options) {
        return TanzeemUnitsApiFp(this.configuration).getTanzeemUnitsChildren(tanzeemUnitKey, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
