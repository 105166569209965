/* tslint:disable */
/* eslint-disable */
/**
 * MKAD Platform API
 * Core MKAD Services
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fatha.idrees@khuddam.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * TanzeemLevelApi - axios parameter creator
 * @export
 */
export const TanzeemLevelApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create a new Tanzeem Level
         * @param {TanzeemLevel} tanzeemLevel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeemLevel: (tanzeemLevel, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemLevel' is not null or undefined
            assertParamExists('addTanzeemLevel', 'tanzeemLevel', tanzeemLevel);
            const localVarPath = `/api/tanzeem/v1/levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(tanzeemLevel, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get a TanzeemLevel
         * @param {string} tanzeemLevelKey TanzeemLevel Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemLevel: (tanzeemLevelKey, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemLevelKey' is not null or undefined
            assertParamExists('getTanzeemLevel', 'tanzeemLevelKey', tanzeemLevelKey);
            const localVarPath = `/api/tanzeem/v1/levels/{tanzeemLevelKey}`
                .replace(`{${"tanzeemLevelKey"}}`, encodeURIComponent(String(tanzeemLevelKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemLevels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemLevels: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/tanzeem/v1/levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemRoles for a TanzeemLevel
         * @param {string} tanzeemLevel TanzeemLevel Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemRolesPerLevel: (tanzeemLevel, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemLevel' is not null or undefined
            assertParamExists('getTanzeemRolesPerLevel', 'tanzeemLevel', tanzeemLevel);
            const localVarPath = `/api/tanzeem/v1/levels/{tanzeemLevel}/roles`
                .replace(`{${"tanzeemLevel"}}`, encodeURIComponent(String(tanzeemLevel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get TanzeemUnits for a TanzeemLevel
         * @param {string} tanzeemLevel TanzeemLevel Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnitsPerLevel: (tanzeemLevel, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeemLevel' is not null or undefined
            assertParamExists('getTanzeemUnitsPerLevel', 'tanzeemLevel', tanzeemLevel);
            const localVarPath = `/api/tanzeem/v1/levels/{tanzeemLevel}/units`
                .replace(`{${"tanzeemLevel"}}`, encodeURIComponent(String(tanzeemLevel)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TanzeemLevelApi - functional programming interface
 * @export
 */
export const TanzeemLevelApiFp = function (configuration) {
    const localVarAxiosParamCreator = TanzeemLevelApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a new Tanzeem Level
         * @param {TanzeemLevel} tanzeemLevel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeemLevel(tanzeemLevel, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTanzeemLevel(tanzeemLevel, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemLevelApi.addTanzeemLevel']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get a TanzeemLevel
         * @param {string} tanzeemLevelKey TanzeemLevel Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemLevel(tanzeemLevelKey, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemLevel(tanzeemLevelKey, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemLevelApi.getTanzeemLevel']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemLevels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemLevels(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemLevels(options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemLevelApi.getTanzeemLevels']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemRoles for a TanzeemLevel
         * @param {string} tanzeemLevel TanzeemLevel Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemRolesPerLevel(tanzeemLevel, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemRolesPerLevel(tanzeemLevel, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemLevelApi.getTanzeemRolesPerLevel']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get TanzeemUnits for a TanzeemLevel
         * @param {string} tanzeemLevel TanzeemLevel Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnitsPerLevel(tanzeemLevel, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeemUnitsPerLevel(tanzeemLevel, page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemLevelApi.getTanzeemUnitsPerLevel']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * TanzeemLevelApi - factory interface
 * @export
 */
export const TanzeemLevelApiFactory = function (configuration, basePath, axios) {
    const localVarFp = TanzeemLevelApiFp(configuration);
    return {
        /**
         * Create a new Tanzeem Level
         * @param {TanzeemLevel} tanzeemLevel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeemLevel(tanzeemLevel, options) {
            return localVarFp.addTanzeemLevel(tanzeemLevel, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a TanzeemLevel
         * @param {string} tanzeemLevelKey TanzeemLevel Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemLevel(tanzeemLevelKey, options) {
            return localVarFp.getTanzeemLevel(tanzeemLevelKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemLevels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemLevels(options) {
            return localVarFp.getTanzeemLevels(options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemRoles for a TanzeemLevel
         * @param {string} tanzeemLevel TanzeemLevel Key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemRolesPerLevel(tanzeemLevel, options) {
            return localVarFp.getTanzeemRolesPerLevel(tanzeemLevel, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TanzeemUnits for a TanzeemLevel
         * @param {string} tanzeemLevel TanzeemLevel Key
         * @param {number} [page] The number of page.
         * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
         * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeemUnitsPerLevel(tanzeemLevel, page, size, sort, options) {
            return localVarFp.getTanzeemUnitsPerLevel(tanzeemLevel, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TanzeemLevelApi - object-oriented interface
 * @export
 * @class TanzeemLevelApi
 * @extends {BaseAPI}
 */
export class TanzeemLevelApi extends BaseAPI {
    /**
     * Create a new Tanzeem Level
     * @param {TanzeemLevel} tanzeemLevel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemLevelApi
     */
    addTanzeemLevel(tanzeemLevel, options) {
        return TanzeemLevelApiFp(this.configuration).addTanzeemLevel(tanzeemLevel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a TanzeemLevel
     * @param {string} tanzeemLevelKey TanzeemLevel Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemLevelApi
     */
    getTanzeemLevel(tanzeemLevelKey, options) {
        return TanzeemLevelApiFp(this.configuration).getTanzeemLevel(tanzeemLevelKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemLevels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemLevelApi
     */
    getTanzeemLevels(options) {
        return TanzeemLevelApiFp(this.configuration).getTanzeemLevels(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemRoles for a TanzeemLevel
     * @param {string} tanzeemLevel TanzeemLevel Key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemLevelApi
     */
    getTanzeemRolesPerLevel(tanzeemLevel, options) {
        return TanzeemLevelApiFp(this.configuration).getTanzeemRolesPerLevel(tanzeemLevel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get TanzeemUnits for a TanzeemLevel
     * @param {string} tanzeemLevel TanzeemLevel Key
     * @param {number} [page] The number of page.
     * @param {number} [size] The numbers of items to return per page. Use -1 for one Page with all Items.
     * @param {string} [sort] Get sorted results. Provide property name and sorting direction, the default direction is ascending. Example: sort&#x3D;title;desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemLevelApi
     */
    getTanzeemUnitsPerLevel(tanzeemLevel, page, size, sort, options) {
        return TanzeemLevelApiFp(this.configuration).getTanzeemUnitsPerLevel(tanzeemLevel, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
