/* tslint:disable */
/* eslint-disable */
/**
 * MKAD Platform API
 * Core MKAD Services
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fatha.idrees@khuddam.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * TanzeemApi - axios parameter creator
 * @export
 */
export const TanzeemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create a new Tanzeem
         * @param {Tanzeem} tanzeem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeem: (tanzeem, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tanzeem' is not null or undefined
            assertParamExists('addTanzeem', 'tanzeem', tanzeem);
            const localVarPath = `/api/tanzeem/v1/tanzeems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(tanzeem, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Tanzeems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeems: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/tanzeem/v1/tanzeems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TanzeemApi - functional programming interface
 * @export
 */
export const TanzeemApiFp = function (configuration) {
    const localVarAxiosParamCreator = TanzeemApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a new Tanzeem
         * @param {Tanzeem} tanzeem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeem(tanzeem, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTanzeem(tanzeem, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemApi.addTanzeem']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         * Get Tanzeems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeems(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTanzeems(options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['TanzeemApi.getTanzeems']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * TanzeemApi - factory interface
 * @export
 */
export const TanzeemApiFactory = function (configuration, basePath, axios) {
    const localVarFp = TanzeemApiFp(configuration);
    return {
        /**
         * Create a new Tanzeem
         * @param {Tanzeem} tanzeem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTanzeem(tanzeem, options) {
            return localVarFp.addTanzeem(tanzeem, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Tanzeems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTanzeems(options) {
            return localVarFp.getTanzeems(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TanzeemApi - object-oriented interface
 * @export
 * @class TanzeemApi
 * @extends {BaseAPI}
 */
export class TanzeemApi extends BaseAPI {
    /**
     * Create a new Tanzeem
     * @param {Tanzeem} tanzeem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemApi
     */
    addTanzeem(tanzeem, options) {
        return TanzeemApiFp(this.configuration).addTanzeem(tanzeem, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Tanzeems
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TanzeemApi
     */
    getTanzeems(options) {
        return TanzeemApiFp(this.configuration).getTanzeems(options).then((request) => request(this.axios, this.basePath));
    }
}
