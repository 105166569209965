import {Configuration, TanzeemApi, TanzeemLevelApi, TanzeemUnitsApi} from "nap-tanzeem-client";
import environments from "./environments";
import {keycloak} from "../app_layout/Keycloak";
import {MeApi} from "nap-auth-client";
import {isMKADAccount} from "./userHelper";

export default class TanzeemClient {
    store;

    constructor(store) {
        this.store = store;
    }

    accessToken = () => {
        return isMKADAccount(keycloak) ? keycloak?.token : "";
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: {Authorization: 'Bearer ' + this.accessToken()},
        };
        openapiConfig.basePath = environments.tanzeemApiUrl;
        return openapiConfig;
    };

    configurationAuth = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: {Authorization: 'Bearer ' + this.accessToken()},
        };
        openapiConfig.basePath = environments.accountsApiUrl;
        return openapiConfig;
    };

    tanzeemApi = () => {
        return new TanzeemApi(this.configuration());
    };

    tanzeemLevelApi = () => {
        return new TanzeemLevelApi(this.configuration());
    };

    tanzeemUnitsApi = () => {
        return new TanzeemUnitsApi(this.configuration());
    };
    
    meApi = () => {
        return new MeApi(this.configurationAuth())
    }

}