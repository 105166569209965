import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {useDispatch, useSelector} from "react-redux";
import {loadTanzeemLevels, loadTanzeems} from "../../../store/tanzeem/tanzeem.slice";
import CreateUpdateEventType from "./CreateUpdateEventType";
import {
    createEventType,
    deleteEventTypes,
    loadEventTypes,
    updateEventType
} from "../../../store/eventTypes/eventTypes.slice";
import FullOverlaySpinner from "../../../components/LoadingSpinner/FullOverlaySpinner";
import {DataTableStateEvent} from "primereact/datatable";
import {defaultErrorMessageToast, getToast} from "../../../utils/toastHelper";


export const participantsOptions = [
    {
        label: "Alle",
        value: "ALL"

    },
    {
        label: "Amla",
        value: "AMLA"
    }
]

export const optionalDataOptions = [
    {
        label: "Pflichtangabe",
        value: "required"

    },
    {
        label: "Optional",
        value: "optional"
    },
    {
        label: "nicht Anzeigen",
        value: "not-available"
    }
]

export const booleanOptions = [
    {
        label: "Ja",
        value: true

    },
    {
        label: "Nein",
        value: false
    }
]

const EVENT_TYPES_PAGING_SIZE = 10;
export default function EventTypes() {
    
    const dispatch =  useDispatch();
    const {tanzeems ,tanzeemLevels, tanzeemsLoading, tanzeemLevelsLoading} = useSelector((state: IRootState) => state.tanzeem)
    const [tableRows, setTableRows] = useState(EVENT_TYPES_PAGING_SIZE)
    const [firstRowIndex, setFirstRowIndex] = useState(0)
    
    const {eventTypes, eventTypesLoading, createEventTypeLoading, updateEventTypeLoading, deleteEventTypeLoading} = useSelector(state => state.eventTypes);
    const [eventTypeDialog, setEventTypeDialog] = useState(false);
    const [deleteEventTypeDialog, setDeleteEventTypeDialog] = useState(false);
    const [deleteEventTypesDialog, setDeleteEventTypesDialog] = useState(false);
    const [eventType, setEventType] = useState();
    const [selectedEventTypes, setSelectedEventTypes] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = getToast();
    const toastErrorMessage = (response) => {defaultErrorMessageToast(toast,response)};
    const dt = useRef(null);
    
    const isTableLoading = () => {
        return tanzeemsLoading || tanzeemLevelsLoading || eventTypesLoading
    } 
    
    useEffect(() =>{
        if(!tanzeems || (tanzeems.length && tanzeems.length === 0))
        {
            dispatch(loadTanzeems( {finalData: {},callbackError: toastErrorMessage}))
        }

        if(!tanzeemLevels || (tanzeemLevels.length && tanzeemLevels.length === 0))
        {
            dispatch(loadTanzeemLevels( {finalData: {},callbackError: toastErrorMessage}))
        }
        console.log("Loading EventTypes init useEffect")
        loadItems();
    },[])
    
    const loadItems = (options: DataTableStateEvent) => {
        if(options)
        {
            setTableRows(options.rows)
            setFirstRowIndex(options.first)
            dispatch(loadEventTypes( {finalData: {page: options.page, size: options.rows}, callbackError: toastErrorMessage}))
        }
        else{
            setTableRows(EVENT_TYPES_PAGING_SIZE)
            setFirstRowIndex(0)
            dispatch(loadEventTypes( {finalData: {page: 0, size: EVENT_TYPES_PAGING_SIZE}, callbackError: toastErrorMessage}))
        }
    }

    const openNew = () => {
        setEventType(null)
        setEventTypeDialog(true);
    };

    const hideDialog = () => {
        setEventTypeDialog(false);
    };

    const hideDeleteEventTypeDialog = () => {
        setDeleteEventTypeDialog(false);
    };

    const hideDeleteEventTypesDialog = () => {
        setDeleteEventTypesDialog(false);
    };

    const saveEventType = (data) => {
        
        if (data) {
            const editedEventType = {
                id: eventType ? eventType.id : null,
                label:	data.label,
                tanzeem: data.tanzeem.key,
                tanzeemLevel: data.tanzeemLevel.key,
                eventTypeFor: data.participants,
                budgetRequired:	data.budgetRequired,
                budgetSelfInvolvementRequired: data.budgetSelfInvolvementRequired,
                agendaRequired: data.agendaRequired,
                approvalRequired: data.approvalRequired,
                visitRequestable: data.visitRequestable,
                customTitleSettable: data.customTitleSettable,
                flyerTemplateAvailable:data.flyerTemplateAvailable,
                flyerRequestable: data.flyerRequestable,
            }
            if(eventType && eventType.id)
            {
                dispatch(updateEventType({finalData: editedEventType, callbackSuccess: eventTypeUpdated, callbackError: toastErrorMessage}))    
            }
            else{
                dispatch(createEventType({finalData: editedEventType, callbackSuccess: eventTypeCreated, callbackError: toastErrorMessage}))
            }
            
        }
    };
    
    const eventTypeCreated = (response) => {
        loadItems();
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: "Eventtype: " +response.label + " created!",
            life: 3000
        });
        setEventTypeDialog(false);
    };

    const eventTypeUpdated = (response) => {
        loadItems();
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: "Eventtype: " +response.label + " updated!",
            life: 3000
        });
        setEventTypeDialog(false);
    };
    
    const editEventType = (eventType) => {
        setEventType({...eventType});
        setEventTypeDialog(true);
    };

    const confirmDeleteEventType = (eventType) => {
        setEventType(eventType);
        setDeleteEventTypeDialog(true);
    };

    const deleteEventType = () => {
        dispatch(deleteEventTypes({finalData: [eventType.id], callbackSuccess: () => {
                setDeleteEventTypeDialog(false);
                setEventType(null);
                loadItems();
                toast.current.show({severity: 'success', summary: 'Successful', detail: 'EventType Deleted', life: 3000});
            }, callbackError: toastErrorMessage }));
    };
    
    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteEventTypesDialog(true);
    };

    const deleteSelectedEventTypes = () => {
        dispatch(deleteEventTypes({finalData: selectedEventTypes.map(selectedEventType => selectedEventType.id), callbackSuccess: () => {
                setDeleteEventTypesDialog(false);
                setSelectedEventTypes(null);
                loadItems();
                toast.current.show({severity: 'success', summary: 'Successful', detail: 'EventTypes Deleted', life: 3000});
            }, callbackError: toastErrorMessage }));
    };
    
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew}/>
                <Button label="Delete" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected}
                        disabled={!selectedEventTypes || !selectedEventTypes.length}/>
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        // return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>;
    };

    const tanzeemLevelBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {tanzeemLevels?.find(item => item.key === rowData.tanzeemLevel)?.label}
            </React.Fragment>
        );
    };

    const tanzeemBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {tanzeems?.find(item => item.key === rowData.tanzeem)?.label}
            </React.Fragment>
        );
    };

    const getOptionsTemplate = (itemOptions, valueKey, labelKey) => {

        const defaultValueKey = valueKey ? valueKey : "key"
        const defaultLabelKey = labelKey ? labelKey : "defaultLabel"
        
        return (rowData, options) => {
            const item = itemOptions?.find(item => item[defaultValueKey] === rowData[options.field])
            const showLabel = item ? item[defaultLabelKey] : "N/A"
            return (
                <React.Fragment>
                    {showLabel}
                </React.Fragment>
            );
        }
    };

    const booleanBodyTemplate = (rowData, options) => {
        return (
            <React.Fragment>
                {rowData[options.field] === true ? "Ja" : "Nein"}
            </React.Fragment>
        );
    };

    const optionalOptionsDataTemplate = (rowData, options) => {
        return (
            <React.Fragment>
                {rowData[options.field] === true ? "Ja" : "Nein"}
            </React.Fragment>
        );
    };

    const participantsTemplate = (rowData, options) => {
        return (
            <React.Fragment>
                {rowData[options.field] ? rowData[options.field] === "AMLA" ? "Amla" : "Alle" : ""}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editEventType(rowData)}/>
                <Button icon="pi pi-trash" rounded outlined severity="danger"
                        onClick={() => confirmDeleteEventType(rowData)}/>
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Eventtypes</h4>
            {/*<span className="p-input-icon-left">*/}
            {/*    <i className="pi pi-search"/>*/}
            {/*    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..."/>*/}
            {/*</span>*/}
        </div>
    );
    const deleteEventTypeDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteEventTypeDialog}/>
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteEventType}/>
        </React.Fragment>
    );
    const deleteEventTypesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteEventTypesDialog}/>
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedEventTypes}/>
        </React.Fragment>
    );
    
    return (
        <div className="content">
            {
                //<FullOverlaySpinner show={isTableLoading()}/>
            }
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                
                <DataTable loadingIcon={<i className="pi pi-spin pi-sync" style={{ color: "green", fontSize: '3rem' }}></i>} 
                           loading={isTableLoading()} ref={dt} 
                           value={eventTypes?.content}
                           lazy
                           first={firstRowIndex}
                           onPage={loadItems}
                           totalRecords={eventTypes?.totalElements}
                           selection={selectedEventTypes}
                           onSelectionChange={(e) => setSelectedEventTypes(e.value)}
                           dataKey="id" paginator rows={tableRows} rowsPerPageOptions={[2,5, 10, 25]}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="{first} bis {last} von {totalRecords} Einträgen werden angezeigt"
                           globalFilter={globalFilter} header={header}>
                    <Column selectionMode="multiple" exportable={false}></Column>
                    <Column field="label" header="Name" sortable style={{minWidth: '10rem'}}></Column>
                    <Column field="tanzeem" header="Tanzeem" sortable style={{minWidth: '10rem'}}
                            body={getOptionsTemplate(tanzeems)}></Column>
                    <Column field="tanzeemLevel" header="Tanzeem Level" sortable style={{minWidth: '10rem'}}
                            body={getOptionsTemplate(tanzeemLevels)}></Column>
                    <Column field="eventTypeFor" header="Teilnehmer" body={participantsTemplate}></Column>
                    <Column field="budgetRequired" header="Budget Erforderlich" sortable style={{minWidth: '8rem'}} body={getOptionsTemplate(optionalDataOptions,"value")}></Column>
                    <Column field="budgetSelfInvolvementRequired" header="Selbstbeteiligung Erforderlich" sortable style={{minWidth: '8rem'}} body={getOptionsTemplate(optionalDataOptions,"value")}></Column>
                    <Column field="agendaRequired" header="Agenda Erforderlich" sortable style={{minWidth: '8rem'}} body={getOptionsTemplate(optionalDataOptions,"value")}></Column>
                    <Column field="approvalRequired" header="Freigabe Erforderlich" sortable style={{minWidth: '8rem'}} body={getOptionsTemplate(booleanOptions,"value")}></Column>
                    <Column field="visitRequestable" header="Besuch erwünscht?" sortable style={{minWidth: '8rem'}} body={getOptionsTemplate(optionalDataOptions,"value")}></Column>
                    <Column field="customTitleSettable" header="Eigene Überschrift" sortable style={{minWidth: '8rem'}} body={getOptionsTemplate(optionalDataOptions,"value")}></Column>
                    <Column field="flyerTemplateAvailable" header="Flyer Tmpl. verfügbar" sortable
                            style={{minWidth: '10rem'}} body={getOptionsTemplate(booleanOptions,"value")}></Column>
                    <Column field="flyerRequestable" header="Flyer Anfrage möglich" sortable
                            style={{minWidth: '12rem'}} body={getOptionsTemplate(booleanOptions,"value")}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '10rem'}}></Column>
                </DataTable>
            </div>

            <Dialog visible={eventTypeDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                    header="Programm Art Details" modal className="p-fluid" onHide={hideDialog}>
                <CreateUpdateEventType eventType={eventType} onSubmit={saveEventType}/>
                <FullOverlaySpinner show={createEventTypeLoading || updateEventTypeLoading }/>
            </Dialog>
            
            <Dialog visible={deleteEventTypeDialog} style={{width: '32rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}} header="Confirm" modal
                    footer={deleteEventTypeDialogFooter} onHide={hideDeleteEventTypeDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {eventType && (
                        <span>
                            Are you sure you want to delete <b>{eventType.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteEventTypesDialog} style={{width: '32rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}} header="Confirm" modal
                    footer={deleteEventTypesDialogFooter} onHide={hideDeleteEventTypesDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {eventType && <span>Are you sure you want to delete the selected eventTypes?</span>}
                </div>
            </Dialog>
        </div>
    );
}